<template>
  <TapbarBase>
    <template #buttons>
      <ButtonTapbar to="/" :exact="true" :label="$t('home')">
        <template #svg>
          <fw-icon-home class="w-7 h-7" />
        </template>
      </ButtonTapbar>

      <ButtonTapbar to="/account" :label="$t('account')">
        <template #svg>
          <fw-icon-account-box class="w-7 h-7" />
        </template>
      </ButtonTapbar>
    </template>
  </TapbarBase>
</template>

<script>
import TapbarBase from '@/fw-modules/fw-core-vue/ui/components/tapbars/TapbarBase'
import ButtonTapbar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonTapbar'

export default {
  components: {
    ButtonTapbar,
    TapbarBase
  }
}
</script>

<i18n>
{
  "pt": {
    "home": "Início",
    "account": "Conta"
  },
  "en": {
    "home": "Home",
    "account": "Account"
  }
}
</i18n>
