<template>
  <LayoutDefault :main-sidebar="true" :tapbar="true" full back-to="-1" wide>
    <template #main>
      <PanelNotificationList class="max-w-prose mx-auto" />
    </template>
    <template #tapbar>
      <TapbarMain />
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import PanelNotificationList from '../components/panels/PanelNotificationList'
import TapbarMain from '@/components/tapbars/TapbarMain'

export default {
  components: {
    LayoutDefault,
    PanelNotificationList,
    TapbarMain
  }
}
</script>
